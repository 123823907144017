import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
export const resources = {
  en: {
    translation: {
      "Klarity Booking": "Klarity Booking",
      "Klarity QR": "Klarity QR",
      "Welcome to Klarity": "Welcome to Klarity",
      "Book your online consultation": "Book your online consultation",
      "Enter Your Flight Details:": "Enter Your Flight Details:",
      "Select Flight Date": "Select Flight Date",
      "Select Fight Time": "Select Fight Time",
      Search: "Search",
      Validate: "Validate",
      Continue: "Continue",
      "Slot Details:": "Slot Details:",
      Date: "Date",
      Time: "Time",
      Reserve: "Reserve above appointment slot",
      "Enter your details": "Enter your details",
      "First Name": "First Name",
      "Last Name": "Last Name",
      Email: "Email",
      Phone: "Phone",
      "Date of birth": "Date of birth",
      "House Number": "House Number",
      Street: "Address",
      "Postal code": "Postal code",
      City: "City",
      Country: "Country",
      sex: "Sex",
      passport_number: "Passport Number",
      participant1: "Participant 1",
      participant2: "Participant 2",
      participant3: "Participant 3",
      participant4: "Participant 4",
      Submit: "Submit",
      "privacy-terms": "privacy-terms",
      "Your Booking was successful. An email confirmation will be sent shortly.":
        "Your Booking was successful. An email confirmation will be sent shortly.",
      "Click here to reserve another slot":
        "Click here to reserve another slot",
      "Are You sure you want select this time?":
        "Are You sure you want select this time?",
      Cancel: "Cancel",
      Yes: "Yes",
      "The  flight  date field is required.":
        "The flight date field is required.",
      "The  flight  time field is required.":
        "The flight time field is required.",
      "The  first  name field is required.":
        "The first name field is required.",
      "The  last  name field is required.": "The last name field is required.",
      "The  email field is required.": "The email field is required.",
      "The  email must be a valid email address.":
        "The email must be a valid email address.",
      "The  phone  number field is required.":
        "The  phone  number field is required.",
      "The  date of  birth field is required.":
        "The date of birth field is required.",
      "The  house  number field is required.":
        "The  house  number field is required.",
      "The  street field is required.": "The street field is required.",
      "The  postal code field is required.":
        "The  postal code field is required.",
      "The  passport  number field is required.":
        "The  passport  number field is required.",
      "The  country field is required.": "The country field is required.",
      "The  city field is required.": "The  city field is required.",
      "The  country may only contain letters and spaces.":
        "The  country may only contain letters and spaces.",
      "The  city may only contain letters and spaces.":
        "The  city may only contain letters and spaces.",
      "The  phone  number must be a valid phone number.":
        "The phone  number must be a valid phone number.",
      "After taking the test, return the test kit withthe provided sticker attached and drop it off at your closest branch of Deutsche Post":
        "After taking the test, return the test kit withthe provided sticker attached and drop it off at your closest branch of DHL",

      "The  house  number may only contain letters, numbers, dashes, and spaces.":
        "The  house  number may only contain letters, numbers, dashes, and spaces.",
      "Click here to schedule another appointment":
        "Click here to schedule another appointment",
      "The  gender field is required.": "The gender field is required.",
      "Number of Attendees for the appointment":
        "Select number of appointment attendees",
      "Same as participant 1": "Same as participant 1",
      "Your residential address": "Your residential address",
      "The  attendees field is required.": "The attendees field is required.",
      "The  test  type field is required.": "The Test Type field is required.",
      "Choose from the following available appointments":
        "Choose from the following available appointments",
      "Select your test": "Select your test",
      pcr: "PCR",
      Antigen: "Antigen",
      "Powered by Klarity": "Powered by Klarity",
      Language: "Language",
      "Country of Residency": "Country of Residency",
      "Current Location": "Current Location",
      "The  address field is required.": "The  address field is required.",
      "The  post  code field is required.":
        "The  post  code field is required.",
      "The  kit  number field is required.":
        "The  kit  number field is required.",
      "The first  name field is required.":
        "The first  name field is required.",
      "The last  name field is required.": "The last  name field is required.",
      "The dob field is required.": "The dob field is required.",
      "The phone field is required.": "The phone field is required.",
      "The email field is required.": "The email field is required.",
      "The checked must be accepted.":
        "The DATA SHARING and KLARITY ACCOUNT must be accepted.",
      "The checked2 must be accepted.":
        "The POLICY AGREEMENT must be accepted.",
      "The country field is required.": "The country field is required.",
      "The phone must be a valid phone number.":
        "The phone must be a valid phone number.",
    },
  },
  gr: {
    translation: {
      "Klarity Booking": "Klarity Buchung",
      "Klarity QR": "Klarity QR",
      "Welcome to Klarity": "Willkommen bei Karity",
      "Book your online consultation": "Buchen Sie Ihre Online-Beratung",
      "Enter Your Flight Details:": "Geben Sie Ihre Flugdaten ein::",
      "Select Flight Date": "Flugdatum auswählen",
      "Select Fight Time": "Flugzeit auswählen",
      Search: "Suche starten",
      Validate: "Validate",
      Continue: "Continue",
      "Slot Details:": "Termin Details",
      Date: "Datum",
      Time: "Uhrzeit",
      Reserve: "Den oben angezeigten Termin buchen",
      "Enter your details": "Bitte ausfüllen",
      "First Name": "Vorname",
      "Last Name": "Nachname",
      Email: "Email",
      Phone: "Telefon",
      "Date of birth": "Geburtsdatum",
      "House Number": "Hausnummer",
      Street: "Strasse",
      "Postal code": "Postleitzahl",
      City: "Stadt",
      Country: "Land",
      sex: "Geschlecht",
      participant1: "Teilnehmer 1",
      participant2: "Teilnehmer 2",
      participant3: "Teilnehmer 3",
      participant4: "Teilnehmer 4",
      passport_number: "Passnummer",
      Submit: "Abschicken",
      "privacy-terms": "privacy-terms",
      "Your Booking was successful. An email confirmation will be sent shortly.":
        "Ihr Reservierung ist erfolgt, Sie erhalten in Kürze eine Bestätigung.",
      "Click here to reserve another slot":
        "Klicken Sie hier um einen weiteren Termin zu vereinbaren",
      "Are You sure you want select this time?":
        "Sind Sie sicher, dass Sie diesmal auswählen möchten?",
      Cancel: "Stornieren",
      Yes: "Ja",
      "The  flight  date field is required.":
        "Das Feld für das Flugdatum ist erforderlich.",
      "The  flight  time field is required.":
        "Das Flugzeitfeld ist erforderlich.",
      "The  first  name field is required.":
        "Das Feld für den Vornamen ist erforderlich.",
      "The  last  name field is required.":
        "Das Feld Nachname ist erforderlich.",
      "The  email field is required.": "Das E-Mail-Feld ist erforderlich.",
      "The  email must be a valid email address.":
        "Die E-Mail muss eine gültige E-Mail-Adresse sein.",
      "The  phone  number field is required.":
        "Das Telefonnummernfeld ist erforderlich.",
      "The  date of  birth field is required.":
        "Das Feld Geburtsdatum ist erforderlich.",
      "The  house  number field is required.":
        "Das Feld Hausnummer ist erforderlich.",
      "The  street field is required.": "Das Strasse feld ist erforderlich.",
      "The  postal code field is required.":
        "Das Postleitzahlen feld ist erforderlich.",
      "The  passport  number field is required.":
        "Das  Passnummer ist erforderlich.",
      "The  country field is required.": "Das Länderfeld ist erforderlich.",
      "The  city field is required.": "Das Stadtfeld ist erforderlich.",
      "The  country may only contain letters and spaces.":
        "Das Land darf nur Buchstaben und Leerzeichen enthalten.",
      "The  city may only contain letters and spaces.":
        "Die Stadt darf nur Buchstaben und Leerzeichen enthalten.",
      "The  phone  number must be a valid phone number.":
        "Die Telefonnummer muss eine gültige Telefonnummer sein.   ",
      "After taking the test, return the test kit withthe provided sticker attached and drop it off at your closest branch of Deutsche Post":
        "Nach erfolgtem Test die Rücksendung mitdem beigefügten Aufkleber versehen und beieiner Filliale der Deutsche Post aufgeben.",
      "The  house  number may only contain letters, numbers, dashes, and spaces.":
        "The  house  number may only contain letters, numbers, dashes, and spaces.        ",
      "Click here to schedule another appointment":
        "Klicken Sie hier, um einen weiteren Termin zu vereinbaren",
      "The  gender field is required.": "Das Feld Geschlecht ist erforderlich",
      "Number of Attendees for the appointment":
        "Anzahl der Terminteilnehmer auswählen",
      "The  attendees field is required.":
        "Das Teilnehmerfeld ist erforderlich.",
      "The  test  type field is required.":
        "Das Feld Testtyp ist erforderlich.",
      "Choose from the following available appointments":
        "Choose from the following available appointments",
      "Select your test": "Wählen Sie Ihren Test aus",
      "Same as participant 1": "Dieselbe Adresse wie Teilnehmer 1",
      "Your residential address": "Ihre Wohnadresse",
      pcr: "PCR",
      Antigen: "Antigent",
      "Powered by Klarity": "Unterstützt von Klarity",
      Language: "Sprache",
      "Country of Residency": "Land des Wohnsitzes",
      "Current Location": "Aktueller Standort",
      "The  address field is required.": "Das Adressfeld ist erforderlich.",
      "The  post  code field is required.":
        "Das Postleitzahlenfeld ist erforderlich.",
      "The  kit  number field is required.":
        "The  kit  number field is required.",
      "The first  name field is required.":
        "The first  name field is required.",
      "The last  name field is required.": "The last  name field is required.",
      "The dob field is required.": "The dob field is required.",
      "The phone field is required.": "The phone field is required.",
      "The email field is required.": "The email field is required.",
      "The checked must be accepted.":
        "The DATA SHARING and KLARITY ACCOUNT must be accepted.",
      "The checked2 must be accepted.":
        "The POLICY AGREEMENT must be accepted.",
      "The country field is required.": "The country field is required.",
      "The phone must be a valid phone number.":
        "The phone must be a valid phone number.",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
