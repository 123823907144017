import "../public/style/globals.css";
import "../public/style/componet.css";
import "../public/style/AllFlow.css";
import "../public/style/AllQuestionnaire.css";
import "../public/style/landing.css";
import "../public/style/mobilePhlebotomy.css";
import "../public/style/orderTestKit.css";
import "../public/style/breastCancer.css";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import "../i18n";
import Head from "next/head";
import GoogleAnalyticsComponent from "../components/thirdParty/GoogleAnalytics";
function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Klarity</title>
        <link rel="icon" type="image.jpg" href="/favicon.jpg" />
      </Head>
      <GoogleAnalyticsComponent GA_MEASUREMENT_ID="G-7X9T5C5E3D" />
      <Component {...pageProps} />
    </>
  );
}

export default withTranslation()(MyApp);
