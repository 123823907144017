"use client";

import { GoogleAnalytics } from "nextjs-google-analytics";

export default function GoogleAnalyticsComponent({ GA_MEASUREMENT_ID }) {
  return (
    <>
      <GoogleAnalytics trackPageViews gaMeasurementId={GA_MEASUREMENT_ID} />
    </>
  );
}
